import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";
import authslice from "./features/authslice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import recordSlice from "./features/recordSlice";
const persistConfig = {
  key: 'root',
  storage,
}
const rootReducer = combineReducers({ 
  appState: appStateSlice,
  auth: authslice,
  record:recordSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
  reducer: persistedReducer,
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const persistor = persistStore(store)
