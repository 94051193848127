import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {authInterface} from '../../interface/authInterface'
import {toast} from 'react-toastify'
import * as api from '../api';
const initialState = {
    user: {},
    error: null,
    loading: true,
    islogged: false
};


export const user_login = createAsyncThunk<object,any>('auth/login', async ({login_data,navigate,toast}) => {

    console.log("3",login_data)
    try {
        const response = await api.login(login_data);
        
        toast.success("Login Successfully");
   
        if(response.data.success){
            navigate('/')
        }
        

        return JSON.stringify(response.data);
    }
    catch (error:any) {
        toast.error(error.response.data.message)
        
    }
})
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateUserInfo: (state, action) => {
            state = action.payload
        },
        logout: (state,payload) => {
            state.islogged=false
            state.user={}
        }
    },

    extraReducers:(builder)=>{
        builder.addCase(user_login.fulfilled, (state, {payload}) => {
            console.log("pa",payload)
            state.user =payload;
            state.islogged = true;
            state.loading = false
            
        })
        builder.addCase(user_login.pending,(state, action) => {
            state.loading = true


        })
        builder.addCase(user_login.rejected,(state, action) => {
            state.loading = false

        })
      
    }

})

export const { updateUserInfo,logout } = authSlice.actions;


export default authSlice.reducer;