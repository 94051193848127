import React, { useState } from 'react';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Container, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import { user_login } from '../../redux/features/authslice';
import { authInterface } from '../../interface/authInterface';
import { AppDispatch } from '../../redux/store';
import { toast } from 'react-toastify';
type Props = {};




const Login = (props: Props) => {
  const navigate = useNavigate();
  const dispatch=useDispatch<AppDispatch>();

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    const { name, value } = e.target;
    await setFormData({ ...formData, [name]: value })


  }
  const handleSubmit = async (e: any) => {

    e.preventDefault();
    const login_data = { ...formData }
  
    dispatch(user_login({login_data,navigate,toast}))

  }

  return (
    <Box 
     sx={{
      height:400,
      width:400,
      borderRadius:4,
      boxShadow:'5px 5px 10px #ccc',
      margin:'auto',
      marginTop:'20vh',
      padding:5
     }}
     >
      <Typography variant="h4" marginLeft={'30%'} marginBottom={'10%'}>Login</Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={3} marginBottom={3} marginLeft={0}>
          <TextField required type={'email'}  name="email" label="Email address" onChange={e => handleChange(e)} />

          <TextField
          required
            name="password"
            label="Password"
            onChange={e => handleChange(e)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {/* <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Button variant="contained" fullWidth type="submit">Login</Button>
      </form>

    </Box>

  )

}

export default Login;