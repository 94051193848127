
import axios from "axios";

// const devEnv = process.env.NODE_ENV !== "production";

// const { REACT_APP_DEV_API, REACT_APP_PROD_API } = process.env;

// const API = axios.create({
//   baseURL: `${devEnv ? REACT_APP_DEV_API : REACT_APP_PROD_API}`,
// });

let isProd=true;
const uat_url="https://uatbackend.basoit.com";
const prod_url="https://prodbackend.basoit.com"; 
// let uatUrl="https://a219-103-132-245-41.ngrok.io"

const API = axios.create({
    baseURL: isProd ? prod_url :uat_url
});

export const login = (loginData) => API.post('/users/signin', loginData);
export const getShopping = () => API.get('/shoppings');
export const createNewUtility = (utilityData) => API.post('/utility/new-utility', utilityData);

export const getGame = () => API.get('/games');
export const getPublisher = () => API.get('/games/publisherList');
export const getGenre = () => API.get('/games/genreList');
export const getBanner = () => API.get('/games/bannerList');
export const createNewGame = (gameData) => API.post('/games/new-game', gameData)
export const createNewPublisher = (newPublisherData) => API.post('/games/new-publisher', newPublisherData)
export const addNewGenre = (newGenreData) => API.post('/games/new-genre', newGenreData)
export const addNewBanner = (newBannerData) => API.post('/games/new-banner', newBannerData)

//service
export const getServiceData = () => API.get('/services');
export const addNewServiceBanner = (newBannerData) => API.post('/services/new-banner', newBannerData)
export const addNewServiceCategory= (newCategoryData) => API.post('/services/new-category', newCategoryData)
export const createNewService = (serviceData) => API.post('/services/new-service', serviceData)


//records
export const getRecordData = () => API.get('/records');
export const addNewRecordCategory= (newCategoryData) => API.post('/records/new-category', newCategoryData)
export const createNewRecord = (recordData) => API.post('/records/new-record', recordData);
export const addNewRecordType= (newRecordTypeData) => API.post('/records/new-type', newRecordTypeData)
export const createNewRecordPublisher = (newPublisherData) => API.post('/records/new-publisher', newPublisherData)
export const getRecordPublisher = () => API.get('/records/publisherlist');
export const getRecordCategory = () => API.get('/records/categorylist');
export const getRecordType = () => API.get('/records/typelist');


//shopping
export const getShoppingData = () => API.get('/shoppings');
export const addNewShoppingBanner = (newBannerData) => API.post('/shoppings/new-banner', newBannerData)
export const addNewShoppingCategory= (newCategoryData) => API.post('/shoppings/new-category', newCategoryData)
export const createNewShopping = (newShoppingData) => API.post('/shoppings/new-shopping', newShoppingData)

