import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { logout } from '../../redux/features/authslice';
import { AppDispatch, RootState } from '../../redux/store';


type Props = {};





const Logout = (props: Props) => {
    const dispatch=useDispatch<AppDispatch>()
    const {islogged}= useSelector((state: RootState) => state.auth);

   useEffect(()=>{
    const ok=dispatch(logout(true))
 


   })
   return(
    <h4>
        {islogged && "Logged Succfully"}
    </h4>
  


   )

}

export default Logout;