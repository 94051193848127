import React from 'react';

type Props = {};

const DashboardPage = (props: Props) => {
  return (
    <div>Dashboard Page</div>
  );
};

export default DashboardPage;