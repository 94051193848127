import { Outlet, Navigate } from 'react-router-dom'
import { json } from 'stream/consumers';
import {useSelector} from 'react-redux'
import { RootState } from '../redux/store';
import { useState } from 'react';

const PrivateRoutes = () => {
 const {islogged}= useSelector((state: RootState) => state.auth);
    return(
        islogged? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes;