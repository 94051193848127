import {
  TextField,
  Button,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  createNewRecordCategory,
  getCategoryData,
} from "../../redux/features/recordSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { toast } from 'react-toastify';


type Props = {};
const initialFormValue = {
  category_name: "",
  category_display_mode: "mode1",
  status: "Active",
};
interface Column {
  id?:
    | "s.no"
    | "category_name"
    | "serial_no"
    | "category_display_mode"
    | "status"
    | "createdAt";
  label: string;
  minWidth?: number;
  align?: "right";
  status?: string;
  format?: (value: number) => string;
}
const columns: readonly Column[] = [
  { id: "s.no", label: "S.No", minWidth: 50 },
  { id: "category_name", label: "Name", minWidth: 170 },
  { id: "serial_no", label: "Rank", minWidth: 170 },
  {
    id: "category_display_mode",
    label: "Category Display Mode",
    minWidth: 170,
  },
  { id: "status", label: "Status", minWidth: 170 },
  { id: "createdAt", label: "Date of Creation", minWidth: 170 },
];
const AddRecordCategoryPage = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { categoryList } = useSelector((state: RootState) => state.record);
  const [formValue, setFormValue] = useState(initialFormValue);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const newRecordCategoryData = { ...formValue };
    dispatch(createNewRecordCategory({ newRecordCategoryData })).then(
      (success) => {
        toast.success("Category added successfully")
        dispatch(getCategoryData());
      }
    );
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };
  //Table handler

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (!categoryList || categoryList?.length == 0) {
      dispatch(getCategoryData());
    }
  }, []);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          required
          id="category_name"
          label="Category Name"
          name="category_name"
          onChange={handleChange}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="category_display_mode">
            Category Display Mode
          </InputLabel>
          <Select
            labelId="category_display_mode"
            id="category_display_mode"
            name="category_display_mode"
            fullWidth
            required
            label="Category Display Mode"
            onChange={handleChange}
          >
            <MenuItem value="mode1">Mode 1</MenuItem>
            <MenuItem value="mode2">Mode 2</MenuItem>
            <MenuItem value="mode3">Mode 3</MenuItem>
            <MenuItem value="mode4">Mode 4</MenuItem>
            <MenuItem value="mode5">Mode 5</MenuItem>
            <MenuItem value="mode6">Mode 6</MenuItem>
            <MenuItem value="mode7">Mode 7</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel id="label_status_id">Status</InputLabel>
          <Select
            labelId="label_status_id"
            id="status"
            name="status"
            fullWidth
            required
            label="Status"
            defaultValue="Active"
            onChange={handleChange}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Disable">Disable</MenuItem>
          </Select>
        </FormControl>
        <Button type="submit" variant="contained" fullWidth>
          Add New Category Mode
        </Button>
      </form>

      {categoryList && (
        <div>
          <Typography
            sx={{
              marginBottom: 5,
              marginTop: 5,
              display: "flex",
              justifyContent: "center",
            }}
            variant="h3"
          >
            Category List
          </Typography>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryList &&
                    categoryList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            {columns.map((column: any) => {
                              let value;
                              if (column.id === "s.no") {
                                value = index + 1;
                              } else {
                                value = row[column.id];
                              }

                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={categoryList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}
    </>
  );
};

export default AddRecordCategoryPage;
