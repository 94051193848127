import React from 'react';

type Props = {};

const HomePage = (props: Props) => {
  return (
    <div>HomePage</div>
  );
};

export default HomePage;