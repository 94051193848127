import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import * as api from '../api'


const initialState = {
    recordsdata: [],
    categoryList:[],
    publisherList:[],
    loading: true,
    typeList:[]
}

export const getRecordData = createAsyncThunk('record/getRecordData', async () => {
    

    try {
        const response = await api.getRecordData();
        return JSON.stringify(response);

    } catch (error:any) {
        toast.error(error.message || error.response.data.message)
        console.log("error",error)

    }

})
export const getPublisherData = createAsyncThunk('record/getPublisher', async () => {

    try {
        const response = await api.getRecordPublisher();
        return JSON.stringify(response);

    } catch (error:any) {
        toast.error(error.response.data.message)
    }
    
})

export const getCategoryData = createAsyncThunk('record/getRecordCategory', async () => {

    try {
        const response = await api.getRecordCategory();
        return JSON.stringify(response);

    } catch (error:any) {
        toast.error(error.response.data.message)
    }
    
})

export const getTypeData = createAsyncThunk('record/getTypeData', async () => {

    try {
        const response = await api.getRecordType();
        return JSON.stringify(response);

    } catch (error:any) {
        toast.error(error.response.data.message)
    }
    
})


// export const getGenreData = createAsyncThunk('game/getGenre', async () => {

//     try {
//         const response = await api.getGenre();
//         toast.success("Genre List loaded")
//         return JSON.stringify(response);

//     } catch (error:any) {
//         toast.error(error.response.data.message)
//     }
    
// })
// export const getBannerData = createAsyncThunk('game/getBanner', async () => {

//     try {
//         const response = await api.getBanner();
//         toast.success("Banner List loaded")
//         return JSON.stringify(response);

//     } catch (error:any) {
//         toast.error(error.response.data.message)
//     }
    
// })
export const createNewRecord = createAsyncThunk<object,any>('record/createNewRecord', async ({newRecordData}) => {

    try {
        
        const response = await api.createNewRecord(newRecordData);
        toast.success("Record Added Successfully");
        return JSON.stringify(response.data);

    } catch (error:any) {
        toast.error(error.response.data.message)
    }

})

export const createNewPublisher = createAsyncThunk<object,any>('record/createNewPublisher', async ({newpublisherData,toast}) => {

    try {
     
        const response = await api.createNewRecordPublisher(newpublisherData);
        toast.success("Publisher Added Successfully");
        return JSON.stringify(response.data);

    } catch (error:any) {
        toast.error(error.response.data.message)
    }

})


export const createNewRecordCategory = createAsyncThunk<object,any>('record/createNewRecordCategory', async ({newRecordCategoryData,toast}) => {

    try {
        const response = await api.addNewRecordCategory(newRecordCategoryData);
        toast.success("Category Added Successfully");
        return JSON.stringify(response.data.data);

    } catch (error:any) {
        toast.error(error.response.data.message)
    }

})

export const createNewRecordType = createAsyncThunk<object,any>('record/createNewRecordType', async ({newtypeData,toast}) => {

    try {
        const response = await api.addNewRecordType(newtypeData);
        toast.success("Type Added Successfully");
        return JSON.stringify(response.data.data);

    } catch (error:any) {
        toast.error(error.response.data.message)
    }

})


const recordSlice = createSlice({
    name: 'record',
    initialState,
    reducers: {},
    extraReducers:(builder)=> {

        builder.addCase(getRecordData.rejected, (state, action) => {

        })
        builder.addCase(getRecordData.pending, (state, action) => {

        })
        builder.addCase(getRecordData.fulfilled, (state, {payload}) => {
            if(payload)
            {
                const data=JSON.parse(payload).data;
                state.recordsdata=data.data;
                state.categoryList=data.category;  
            }  
        })

        builder.addCase(getPublisherData.fulfilled, (state, {payload}) => {
            if(payload)
            {
                const data=JSON.parse(payload).data;
                state.publisherList=data.data;
                
            }  
        })  
        builder.addCase(getCategoryData.fulfilled, (state, {payload}) => {
            if(payload)
            {
                const data=JSON.parse(payload).data;
                state.categoryList=data.data;
                
            }  
        })  

        builder.addCase(getTypeData.fulfilled, (state, {payload}) => {
            if(payload)
            {
                const data=JSON.parse(payload).data;
                state.typeList=data.data;
                
            }  
        }) 
    }

})

export default recordSlice.reducer;