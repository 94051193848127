import HomePage from "../pages/home/HomePage";
import { RouteType } from "./config";
import ComponentPageLayout from "../pages/component/ComponentPageLayout";

//icons
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import DashboardPage from "../pages/dashboard/DashboardPage";

import Logout from "../pages/auth/Logout";
import AddRecordCategoryPage from "../pages/Records/AddRecordCategoryPage";
import AddRecordsPage from "../pages/Records/AddRecordsPage";
import ViewAllRecordsPage from "../pages/Records/ViewAllRecordsPage";
import AddRecordPublisherPage from "../pages/Records/AddRecordPublisherPage";
import AddRecordTypePage from "../pages/Records/AddRecordTypePage";


const appRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: "home"
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
    state: "Dashboard",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <DashboardCustomizeOutlinedIcon />
    }
  },

  {
    path: "/appdata",
    element: <ComponentPageLayout />,
    state: "app_data",
    sidebarProps: {
      displayText: "Basoit App Record ",
      icon: <DesignServicesOutlinedIcon />
    },
    child: [
      {
        path: "/appdata/listallrecords",
        element: <ViewAllRecordsPage />,
        state: "appdata.listallrecords",
        sidebarProps: {
          displayText: "List All Data"
        },
      },
      {
        path: "/appdata/addrecords",
        element: <AddRecordsPage />,
        state: "appdata.addrecords",
        sidebarProps: {
          displayText: "Add Records"
        },
      },
      {
        path: "/appdata/addrecordcategory",
        element: <AddRecordCategoryPage />,
        state: "appdata.addrecordcategory",
        sidebarProps: {
          displayText: "Add Category"
        }
      },
      {
        path: "/appdata/addrecordpublisher",
        element: <AddRecordPublisherPage />,
        state: "appdata.addrecordpublisher",
        sidebarProps: {
          displayText: "Add Publisher"
        }
      },
      {
        path: "/appdata/addrecordtype",
        element: <AddRecordTypePage />,
        state: "appdata.addrecordtype",
        sidebarProps: {
          displayText: "Add Type"
        }
      }
      
    ]
  },
  {
    path: "/logout",
    element: <Logout />,
    state: "logout",
    sidebarProps: {
      displayText: "Logout",
      icon: <LogoutOutlinedIcon />
    }
  },
  

];

export default appRoutes;