import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import MainLayout from "./components/layout/MainLayout";
import Login from "./pages/auth/Login";
import { routes } from "./routes";
import PrivateRoutes from "./utils/ProtectedRoutes";
import 'react-toastify/dist/ReactToastify.css';
;

function App() {
  return (
    <BrowserRouter>
     <ToastContainer />
      <Routes>
        <Route element={<PrivateRoutes/>}>
          <Route path="/" element={<MainLayout />}>
          {routes}
        </Route>
        </Route>
        <Route path="/login" element={<Login/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
