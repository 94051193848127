import {
    TextField,
    Button,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    TablePagination,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { createNewRecordType,getTypeData  } from '../../redux/features/recordSlice';
  import { AppDispatch, RootState } from "../../redux/store";
  import { toast } from "react-toastify";
  
  
  type Props = {};
  const initialFormValue = {
    type_name: "",
    status: "Active",
  };
  interface Column {
    id?: "s.no" | "type_name" | "status" | "createdAt";
    label: string;
    minWidth?: number;
    align?: "right";
    status?: string;
    format?: (value: number) => string;
  }
  const columns: readonly Column[] = [
    { id: "s.no", label: "S.No", minWidth: 50 },
    { id: "type_name", label: "Name", minWidth: 170 },
    { id: "status", label: "Status", minWidth: 170 },
    { id: "createdAt", label: "Date of Creation", minWidth: 170 },
  ];
  const AddRecordTypePage = (props: Props) => {
  
    const { typeList } = useSelector((state: RootState) => state.record);
    console.log("typeList", typeList);
  
    const dispatch = useDispatch<AppDispatch>();
    const [typeData, settypeData] = useState(initialFormValue);
  
    //Table handler
  
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    useEffect(() => {

        dispatch(getTypeData());

    }, []);
  
    const handleSubmit = (e: any) => {
      e.preventDefault();
      const newtypeData = { ...typeData };
  
      dispatch(createNewRecordType({ newtypeData, toast })).then(
        (success) => {
          dispatch(getTypeData());
        }
      );
    };
    const handleChange = (e: any) => {
      const { name, value } = e.target;
      settypeData({ ...typeData, [name]: value });
    };
    return (
      <>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            variant="outlined"
            fullWidth
            id="record_type"
            label="Type Name"
            name="type_name"
            onChange={handleChange}
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="label_status_id">Status</InputLabel>
            <Select
              labelId="label_status_id"
              id="record_status"
              name="status"
              fullWidth
              label="Status"
              required
              defaultValue="Active"
              onChange={handleChange}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Disable">Disable</MenuItem>
            </Select>
          </FormControl>
  
          <Button type="submit" variant="contained" fullWidth>
            Add New type
          </Button>
        </form>
  
        {typeList && (
          <div>
            <Typography
              sx={{
                marginBottom: 5,
                marginTop: 5,
                display: "flex",
                justifyContent: "center",
              }}
              variant="h3"
            >
              type List
            </Typography>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {typeList &&
                      typeList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row: any, index:number) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columns.map((column: any) => {
                                let value;
                                if (column.id === "s.no") {
                                  value = index + 1;
                                } else {
                                  value = row[column.id];
                                }
  
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={typeList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        )}
      </>
    );
  };
  
  export default AddRecordTypePage;
  