import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";

import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { createNewRecord, getCategoryData, getPublisherData,getTypeData } from "../../redux/features/recordSlice";

const AddRecordsPage = () => {
  const initialFormValue = {
    name: "",
    badge_text: "",
    logo_url: "",
    url: "",
    publisher: "",
    type: "",
    description: "",
    status: "Active",
  };

  const dispatch = useDispatch<AppDispatch>();
  const { categoryList, publisherList, typeList } = useSelector(
    (state: RootState) => state.record
  );
  const [formValues, setFormValues] = useState(initialFormValue);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(name, value);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const newRecordData = { ...formValues };
    dispatch(createNewRecord({ newRecordData }));
  };

  useEffect(() => {
    dispatch(getCategoryData());
    dispatch(getPublisherData());
    dispatch(getTypeData());
   console.log("publisherList",publisherList)
    // if (categoryList?.length == 0 || !categoryList){
    //   dispatch(getCategoryData());
    // } 
    // if (publisherList?.length == 0 || !publisherList){
    //   dispatch(getPublisherData());
    // } 
    // if (typeList?.length == 0 || !typeList){
    //   dispatch(getTypeData());
    // } 
  }, []);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          required
          id="name"
          label="Name"
          name="name"
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="badge_text"
          label="Badge Text"
          name="badge_text"
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          type={"url"}
          variant="outlined"
          id="url"
          label="Url"
          name="url"
          onChange={handleChange}
        />
        <TextField
          margin="normal"
          fullWidth
          required
          type={"url"}
          variant="outlined"
          id="logo_url"
          label="Logo Url"
          name="logo_url"
          onChange={handleChange}
        />

        <FormControl fullWidth margin="normal" required>
          <InputLabel id="label_publisher">Publisher</InputLabel>
          <Select
            labelId="label_publisher"
            id="publisher"
            fullWidth
            
            name="publisher"
            onChange={handleChange}
            label="Publisher"
          >
            {publisherList?.map((item: any) => (
              <MenuItem value={item.pub_name}>{item.pub_name}</MenuItem>
            ))}
          </Select>
        </FormControl>{" "}
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="label_status_id">Type</InputLabel>
          <Select
            labelId="label_type_id"
            id="add_record_type"
            name="type"
            fullWidth
            
            label="Type"
            onChange={handleChange}
          >
            {typeList?.map((item: any) => (
              <MenuItem value={item.type_name}>{item.type_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="label_category_id">Category</InputLabel>
          <Select
            labelId="label_category_id"
            id="add_record_category_id"
            fullWidth
            name="category_name"
            onChange={handleChange}
            label="Category"
          >
            {categoryList?.map((item: any) => (
              <MenuItem value={item.category_name}>
                {item.category_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="label_status_id">Status</InputLabel>
          <Select
            labelId="label_status_id"
            id="add_record_status"
            name="status"
            fullWidth
            required
            label="Status"
            defaultValue="Active"
            onChange={handleChange}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Disable">Disable</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={8}
          fullWidth
          placeholder="Write Description..."
          name="description"
          onChange={handleChange}
        />
        <Button type="submit" variant="contained" fullWidth>
          Add Product
        </Button>
      </form>
    </div>
  );
};

export default AddRecordsPage;
