import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  TextField,
  Button,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TablePagination,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import { getRecordData } from "../../redux/features/recordSlice";

interface Column {
  id?:
    | "s.no"
    | "name"
    | "publisher"
    | "serial_no"
    | "type"
    | "category_name"
    | "url"
    | "status"
    | "createdAt";
  label: string;
  minWidth?: number;
  align?: "right";
  status?: string;
  format?: (value: number) => string;
}
const columns: readonly Column[] = [
  { id: "s.no", label: "S.No", minWidth: 50 },
  { id: "serial_no", label: "Rank", minWidth: 50 },
  { id: "name", label: "Name", minWidth: 140 },
  { id: "publisher", label: "Publisher", minWidth: 100 },
  { id: "type", label: "Type", minWidth: 100 },
  { id: "category_name", label: "Category", minWidth: 100 },
  { id: "url", label: "Url", minWidth: 140 },
  { id: "status", label: "Status", minWidth: 170 },
  { id: "createdAt", label: "Date of Creation", minWidth: 170 },
];
const ViewAllRecordsPage = () => {
  const { recordsdata } = useSelector((state: RootState) => state.record);
  const dispatch = useDispatch<AppDispatch>();
  //Table handler

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  useEffect(() => {
      dispatch(getRecordData());
  }, []);

  return (
    recordsdata && (
      <div>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {recordsdata &&
                  recordsdata
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column: any) => {
                            let value;
                            if (column.id === "s.no") {
                              value = index + 1;
                            } else {
                              value = row[column.id];
                            }

                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={recordsdata.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    )
  );
};

export default ViewAllRecordsPage;
